import { Button, Menu } from '@mantine/core';
import {
    FaArrowRightFromBracket,
    FaChevronRight,
    FaUser,
} from 'react-icons/fa6';
import { API_URL } from '../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function LoggedUserMenu({ userHandle }: { userHandle: string }) {
    const navigate = useNavigate();

    const logout = async () => {
        try {
            await axios.get(`${API_URL}/auth/logout`);

            window.location.href = '/';
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Menu>
            <Menu.Target>
                <Button color='orange' size='md' radius='xs' variant='outline'>
                    {userHandle}
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item
                    leftSection={<FaUser />}
                    rightSection={<FaChevronRight />}
                    onClick={() => navigate('/profile')}
                >
                    Show profile
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                    color='red'
                    leftSection={<FaArrowRightFromBracket />}
                    rightSection={<FaChevronRight />}
                    onClick={logout}
                >
                    Logout
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}
