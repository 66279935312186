import { useEffect, useState } from 'react';
import Order from '../../model/Order';
import axios from 'axios';
import { API_URL } from '../../config';
import {
    Button,
    Center,
    Flex,
    Modal,
    TextInput,
    Title,
    Text,
    Box,
    Loader,
    Stack,
    Anchor,
} from '@mantine/core';
import Barcode from 'react-barcode';

type onModalCloseHandler = (orderInstance: Order | null) => void;
type onModalClose = () => void;

export default function OrderInfoModal({
    showModal,
    closeModal,
    orderId,
    onModalClose,
}: {
    showModal: boolean;
    closeModal: onModalClose;
    orderId: number | null;
    onModalClose: onModalCloseHandler;
}) {
    const [isOrderCodeLoading, setIsOrderCodeLoading] = useState(true);
    const [orderCode, setOrderCode] = useState<string | null>(null);
    const [showMessages, setShowMessages] = useState(0);
    const [orderInstance, setOrderInstance] = useState<Order | null>(null);

    const getOrderCode = async () => {
        setIsOrderCodeLoading(true);

        try {
            const response = await axios.get(`${API_URL}/orders/${orderId}`);
            setOrderCode(response.data.collection_code);
        } catch (error) {
            console.log(error);
        }

        setIsOrderCodeLoading(false);
    };

    useEffect(() => {
        if (orderId == null) return;
        getOrderCode();
    }, [orderId]);

    useEffect(() => {
        if (orderInstance == null) return;
        handleModalClose();
    }, [orderInstance]);

    const handleModalClose = () => {
        onModalClose(orderInstance);
        closeModal();
    };

    const handleCodeClaim = async () => {
        // TODO
    };

    const handleContinue = async () => {
        if (showMessages === 0) return;

        try {
            const endpoint = showMessages === 1 ? 'confirm' : 'reject';

            await axios.post(`${API_URL}/orders/${orderId}/${endpoint}`);
            const order: Order = (
                await axios.get(`${API_URL}/orders/${orderId}`)
            ).data;
            onModalClose(order);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            title='Get pickup code'
            size='lg'
            opened={showModal}
            onClose={handleModalClose}
        >
            <Center>
                <Stack>
                    <Center>
                        <Title>Your pickup code</Title>
                    </Center>

                    <Box hidden={!isOrderCodeLoading}>
                        <Loader type='bars' />
                    </Box>

                    <Box hidden={isOrderCodeLoading}>
                        {orderCode != null ? (
                            <Stack>
                                <Center>
                                    <Barcode
                                        value={orderCode}
                                        format='CODE128'
                                        width={4}
                                        height={150}
                                    />
                                </Center>

                                <Flex justify='center' gap='xs'>
                                    <Button
                                        variant={
                                            showMessages === 1
                                                ? 'filled'
                                                : 'outline'
                                        }
                                        onClick={() => setShowMessages(1)}
                                    >
                                        I've picked up the order
                                    </Button>
                                    <Button
                                        variant={
                                            showMessages === 2
                                                ? 'filled'
                                                : 'subtle'
                                        }
                                        color='black'
                                        onClick={() => setShowMessages(2)}
                                    >
                                        I've had problems with the code
                                    </Button>
                                </Flex>

                                <Stack hidden={showMessages === 0}>
                                    <Text hidden={showMessages !== 1}>
                                        By accepting you guarantee that you have
                                        received and correctly picked up the
                                        order.
                                    </Text>
                                    <Text hidden={showMessages !== 2}>
                                        To solve the problems contact us at{' '}
                                        <Anchor>info@bitpost.it</Anchor>
                                    </Text>
                                    <Text>
                                        For more info read our{' '}
                                        <Anchor>terms and conditions.</Anchor>
                                    </Text>

                                    <Flex
                                        justify='center'
                                        onClick={handleContinue}
                                    >
                                        <Button>I understand. Continue</Button>
                                    </Flex>
                                </Stack>
                            </Stack>
                        ) : (
                            <Center>
                                <Stack>
                                    <Text>
                                        You haven't claimed the code yet.{' '}
                                        <Text span fw='bold'>
                                            Make sure to be in front of the
                                            locker before claiming it.
                                        </Text>
                                    </Text>
                                    <Button onClick={handleCodeClaim}>
                                        Claim code
                                    </Button>
                                </Stack>
                            </Center>
                        )}
                    </Box>
                </Stack>
            </Center>
        </Modal>
    );
}
