export const currencies: Record<string, string> = {
    EUR: '€',
    USD: '$',
    GBP: '£',
};

export interface ScrapedProductInfo {
    order_id: number;
    price: number;
    currency: string;
    color: string;
    size: string;
    title: string;
    pictures: string[];
}
