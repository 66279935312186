import { AxiosResponse } from 'axios';
import { Mnemonic, Network, Signer } from 'lwk_wasm';
import CryptoJS from 'crypto-js';

export function encryptMnemonic(plaintext: string, secret: string) {
    var key = CryptoJS.enc.Utf8.parse(secret);
    let iv = CryptoJS.lib.WordArray.create(key.words.slice(0, 4));

    // Encrypt the plaintext
    var cipherText = CryptoJS.AES.encrypt(plaintext, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return {
        ct: cipherText.toString(),
        iv: CryptoJS.enc.Base64.stringify(iv),
    };
}

export function decryptMnemonic(
    cipherText: string,
    secret: string,
    iv: string,
) {
    let iv1 = CryptoJS.enc.Base64.parse(iv);

    var key = CryptoJS.enc.Utf8.parse(secret);
    var cipherBytes = CryptoJS.enc.Base64.parse(cipherText);
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: cipherBytes,
    });

    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: iv1,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function checkMnemonic(mnemonic: string, xpub: string) {
    let signer = null;
    try {
        signer = new Signer(new Mnemonic(mnemonic), Network.mainnet());
    } catch (e) {
        return false;
    }

    if (xpub !== signer.getMasterXpub().toString()) {
        // TODO: make this more visible
        console.error("Mnemonic doesn't match");
        return false;
    }

    return true;
}

export function isValidMnemonic(mnemonic: string) {
    try {
        new Mnemonic(mnemonic);
        return true;
    } catch (_) {}
    return false;
}
