import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { useParams } from 'react-router-dom';
import Order from '../../model/Order';
import { Button, Form, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Stepper } from '@mantine/core';
import { FaAmazon, FaBitcoin, FaCashRegister } from 'react-icons/fa6';

export default function TakeOrder() {
    const [stepperActivePage, setStepperActivePage] = useState(0);

    let { id } = useParams();
    const [loading, isLoading] = useState(false);
    const [error, setError] = useState();
    const [order, setOrder] = useState<Order>();
    const [key, setKey] = useState('confirmation');
    const [takingTooLong, setTakingTooLong] = useState(false);

    useEffect(() => {
        console.log(id);
        if (id) getOrder(id);
    }, []);

    if (!window.loggedUser) {
        return <h1>Please login first</h1>;
    }

    const getOrder = async (orderId: string) => {
        isLoading(true);
        await axios.get(`${API_URL}/orders/${orderId}`).then((response) => {
            setOrder(response.data);
            isLoading(false);
        });
    };

    const takeOrder = async (orderId: Number) => {
        isLoading(true);
        axios
            .post(
                `${API_URL}/orders/${orderId}/mine`,
                {},
                { headers: { Authorization: window.loggedUser.token } },
            )
            .then((resp) => {
                setKey('wait');
                waitForPayment(orderId);
                isLoading(false);
            });
    };

    const waitForPayment = async (id: Number) => {
        let pollingLoading = false;
        let count = 0;
        let interval = setInterval(() => {
            if (pollingLoading) {
                return;
            }

            if (count >= 100) {
                clearInterval(interval);
                setTakingTooLong(true);
            }
            pollingLoading = true;
            axios
                .get(`${API_URL}/orders/${id}`, {
                    headers: { Authorization: window.loggedUser.token },
                })
                .then((resp) => {
                    pollingLoading = false;
                    count++;
                    console.log(resp);

                    if (resp.data.status_id == 3) {
                        clearInterval(interval);
                        setKey('buy');
                    }
                });
        }, 5000);
    };

    const postTrackingInfo = async (orderId: number) => {
        const url = (
            document.getElementById('tracking-url') as HTMLInputElement
        ).value;

        isLoading(true);
        await axios
            .post(
                `${API_URL}/orders/${orderId}/tracking`,
                {
                    data: url,
                },
                {
                    headers: {
                        Authorization: window.loggedUser.token,
                    },
                },
            )
            .then((response) => {
                console.log(response.data);
                setKey('completed');
                isLoading(false);
            });
    };

    return (
        <Stepper active={stepperActivePage}>
            <Stepper.Step
                label='Confirmation'
                description='Confirm the order'
                icon={<FaBitcoin/>}
            >
                
            </Stepper.Step>

            <Stepper.Step
                label='Wait'
                description='Wait for payment'
                icon={<FaCashRegister/>}
            >

            </Stepper.Step>

            <Stepper.Step
                label='Buy'
                description='Buy the product'
                icon={<FaAmazon/>}
            >

            </Stepper.Step>

        </Stepper>
    );

    // return (
    //     <Tabs id='miner-tabs' activeKey={key} className='mb-3'>
    //         <Tab eventKey='confirmation' title='Confirmation' disabled>
    //             <div>
    //                 {order && (
    //                     <div>
    //                         <div
    //                             style={{
    //                                 visibility: loading
    //                                     ? 'visible'
    //                                     : 'collapse',
    //                             }}
    //                         >
    //                             <Spinner animation='border' role='status'>
    //                                 <span className='visually-hidden'>
    //                                     Loading...
    //                                 </span>
    //                             </Spinner>
    //                             <span className='ms-2'>
    //                                 Fetching the order...
    //                             </span>
    //                         </div>
    //                         <div
    //                             style={{
    //                                 visibility: !loading
    //                                     ? 'visible'
    //                                     : 'collapse',
    //                             }}
    //                         >
    //                             <h1>{order.title}</h1>
    //                             <link rel='icon' href={order.url} />
    //                             <a
    //                                 href={order.url}
    //                                 target='_blank'
    //                                 rel='noopener noreferrer'
    //                             >
    //                                 {order.url}
    //                             </a>
    //                             <p>Do you want to take charge of the order?</p>
    //                             <Button onClick={() => takeOrder(order.id)}>
    //                                 Confirm order
    //                             </Button>
    //                         </div>
    //                     </div>
    //                 )}
    //             </div>
    //         </Tab>
    //         <Tab eventKey='wait' title='Wait for payment' disabled>
    //             <div>
    //                 {order && (
    //                     <div>
    //                         <div
    //                             style={{
    //                                 visibility: loading
    //                                     ? 'visible'
    //                                     : 'collapse',
    //                             }}
    //                         >
    //                             <Spinner animation='border' role='status'>
    //                                 <span className='visually-hidden'>
    //                                     Loading...
    //                                 </span>
    //                             </Spinner>
    //                             <span className='ms-2'>
    //                                 Taking the order...
    //                             </span>
    //                         </div>
    //                         <div
    //                             style={{
    //                                 visibility: !loading
    //                                     ? 'visible'
    //                                     : 'collapse',
    //                             }}
    //                         >
    //                             <h1>Order taken!</h1>
    //                             <p></p>
    //                             {takingTooLong
    //                                 ? 'It looks like this will take a while, feel free to close this page. You will get notified once the buyer pays order!'
    //                                 : 'Now wait for the buyer to pay the order...'}
    //                         </div>
    //                     </div>
    //                 )}
    //             </div>
    //         </Tab>
    //         <Tab eventKey='buy' title='Buy' disabled>
    //             <h1>Order payed!</h1>
    //             <p>Now go to amazon and purchase the item.</p>
    //             <p>Here is the information you need to fulfill the order:</p>
    //             {order && (
    //                 <div>
    //                     <h3>Product info:</h3>
    //                     <ul>
    //                         <li>
    //                             <b>Link:</b> {order.url}
    //                         </li>
    //                         <li>
    //                             <b>Product name:</b> {order.title}
    //                         </li>
    //                         <li>
    //                             <b>Price:</b> {order.price}
    //                             {order.currency}
    //                         </li>
    //                         <li>
    //                             <b>Description:</b> {order.desctiption}
    //                         </li>
    //                     </ul>
    //                     <h3>Shipping info:</h3>
    //                     <ul>
    //                         <li>
    //                             <b>Zip code:</b> {order.address_cap}
    //                         </li>
    //                         <li>
    //                             <b>Locker:</b> {order.address_locker}
    //                         </li>
    //                     </ul>
    //                     <p>Come back when you're done!</p>
    //                     <Button onClick={() => setKey('tracking')}>
    //                         Proceed
    //                     </Button>
    //                 </div>
    //             )}
    //         </Tab>
    //         <Tab eventKey='tracking' title='Tracking' disabled>
    //             <div style={{ visibility: loading ? 'visible' : 'collapse' }}>
    //                 <Spinner animation='border' role='status'>
    //                     <span className='visually-hidden'>Loading...</span>
    //                 </Spinner>
    //                 <span className='ms-2'>Updating tracking...</span>
    //             </div>
    //             {order && (
    //                 <div
    //                     style={{
    //                         visibility: !loading ? 'visible' : 'collapse',
    //                     }}
    //                 >
    //                     <h1>Track the order.</h1>
    //                     <Form.Group className='mb-3'>
    //                         <Form.Label>
    //                             Enter the Amazon tracking link of the order.
    //                         </Form.Label>
    //                         <Form.Control
    //                             id='tracking-url'
    //                             type='text'
    //                             placeholder='https://amzn.eu/d8juUH9'
    //                         />
    //                     </Form.Group>
    //                     <Button onClick={() => postTrackingInfo(order.id)}>
    //                         Load info
    //                     </Button>
    //                 </div>
    //             )}
    //         </Tab>
    //         <Tab eventKey='completed' title='Completed' disabled>
    //             <h1>Conratz!</h1>
    //             <h2>The order state is updated!</h2>
    //         </Tab>
    //     </Tabs>
    // );
}
