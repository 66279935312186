import { useEffect, useRef, useState } from 'react';
import { API_URL } from '../../config';
import axios from 'axios';
import Order, { OrderStatus } from '../../model/Order';
import { Mnemonic, Network, Pset, Signer } from 'lwk_wasm';
import { Box, Button, Center, Loader, Modal, Stack, TextInput, Title, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FaRegFaceSmileWink } from 'react-icons/fa6';

type onModalCloseHandler = (orderPaidIstance: Order | null) => void;
type onModalClose = () => void;

export default function ClaimPaymentModal({
    showModal,
    closeModal,
    orderId,
    onModalClose,
}: {
    showModal: boolean;
    closeModal: onModalClose;
    orderId: number | null;
    onModalClose: onModalCloseHandler;
}) {
    const invoiceForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            invoice: ''
        },

        validate: {
            invoice: (value) => value.startsWith('lnbc') ? null : 'Invalid invoice',
        }
    });

    const [amount, setAmount] = useState<number | null>(null);
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
    const [orderPaidIstance, setOrderPaidIstance] = useState<Order | null>(
        null,
    );
    const intervalRef = useRef<any>(null);

    const handleModalClose = () => {
        onModalClose(orderPaidIstance);
    };

    const startCheckOrderStatus = async () => {
        try {
            const response = await axios.get(`${API_URL}/orders/${orderId}`);
            if (response.data.status_id > OrderStatus.RECEIVED) {
                // Show invoice paid message
                // setShowPaymentOk(true);
                setOrderPaidIstance(response.data);

                // Stop the interval which check the payment...
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        } catch (error) {
            console.log(error);
        }
        console.log('Check order status...');
    };

    const submitInvoice = async (values: typeof invoiceForm.values) => {
        // TODO: show error if payment fails

        setIsInvoiceLoading(true);

        try {
            const response = await axios.post(
                `${API_URL}/orders/${orderId}/invoice`,
                { invoice: values.invoice },
            );
            const orig_pset = new Pset(response.data.psbt);
            const signer = new Signer(
                new Mnemonic((window as any).loggedUser.mnemonic),
                Network.mainnet(),
            );
            const signed_pset = signer.sign(orig_pset).toString();
            await axios.post(`${API_URL}/orders/${orderId}/signature/`, {
                signed_psbt: signed_pset,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsInvoiceLoading(false);
        }
        console.log('Check order status...');
    };

    const getOrderInvoiceDetails = async () => {
        setIsInvoiceLoading(true);

        try {
            const response = await axios.get(
                `${API_URL}/orders/${orderId}/invoice`,
            );
            setAmount(response.data.amount);

            setIsInvoiceLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // When the modal is visible, the client start pinging the server
        // to check if the order has been paid (every X seconds)
        const X = 3;
        if (showModal) {
            intervalRef.current = setInterval(startCheckOrderStatus, X * 1000);
            getOrderInvoiceDetails();
        } else if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        // Cleanup...
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [showModal]);

    return (
        <Modal
            title='Claim payment'
            size='md'
            opened={showModal}
            onClose={handleModalClose}
        >
            <Center>
                <Title>Claim payment</Title>
            </Center>

            <Stack hidden={orderPaidIstance != null}>
                <Center>
                    <Box hidden={!isInvoiceLoading}>
                        <Loader type='bars' />
                    </Box>
                    
                    <Box hidden={isInvoiceLoading}>
                        <form onSubmit={invoiceForm.onSubmit(submitInvoice)}>
                            <TextInput
                                label={`Provide an invoice for ${amount} SAT`}
                                placeholder='lnbc...'
                                key={invoiceForm.key('invoice')}
                                {...invoiceForm.getInputProps('invoice')}
                            />

                            <Button type='submit'>Send</Button>
                        </form>
                    </Box>
                </Center>
            </Stack>

            <Stack hidden={orderPaidIstance == null}>
                <Center>
                    <FaRegFaceSmileWink fontSize={100} color='green' />
                </Center>
                <Text>Payment sent. You can now close the window.</Text>
                <Button onClick={handleModalClose}>Close</Button>
            </Stack>

        </Modal>
    );
}
