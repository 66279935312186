import { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import { QRCodeSVG } from 'qrcode.react';
import {
    Stepper,
    TextInput,
    Button,
    Image,
    Center,
    Grid,
    Title,
    Tooltip,
    Text,
    Divider,
    Flex,
    Loader,
} from '@mantine/core';
import {
    FaAmazon,
    FaBitcoin,
    FaHourglassHalf,
    FaInfo,
    FaRegClock,
    FaRegFaceSmileWink,
} from 'react-icons/fa6';
import { useForm } from '@mantine/form';
import { currencies, ScrapedProductInfo } from '../../model/Product';
import { Carousel } from '@mantine/carousel';

import '@mantine/carousel/styles.css';

export default function NewOrder() {
    const urlForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            url: '',
        },
        validate: {
            url: (value) =>
                /^https:\/\/(www\.)?amazon\.(com|[a-z]{2,3})(\/.*)?$/.test(
                    value,
                )
                    ? null
                    : 'Invalid url',
        },
    });

    const [isUrlLoading, setIsUrlLoading] = useState(false);
    const [isPostingOrder, setIsPostingOrder] = useState(false);
    const [stepperActivePage, setStepperActivePage] = useState(0);

    const [product, setProduct] = useState<ScrapedProductInfo | null>(null);
    const [takingTooLong, setTakingTooLong] = useState(false);
    const [invoice, setInvoice] = useState('');

    const nextStep = () => setStepperActivePage((current) => current + 1);
    const prevStep = () => setStepperActivePage((current) => current - 1);

    if (!window.loggedUser) {
        return <h1>Please login first</h1>;
    }

    const getArticleInfoFromUrl = async (values: typeof urlForm.values) => {
        const { url: amazonUrl } = values;

        setIsUrlLoading(true);
        try {
            const response = await axios.post(
                `${API_URL}/amazon/info_from_url`,
                { search: amazonUrl },
            );

            const product = response.data;
            setProduct(product);
            nextStep();
        } catch (error) {
            console.log(error);
        }

        setIsUrlLoading(false);
    };

    const postOrder = async () => {
        if (product == null) return;
        setIsPostingOrder(true);

        try {
            await axios.post(`${API_URL}/orders/${product.order_id}`);
            nextStep();
            startCheckThatOrderIsMined(product.order_id);
        } catch (error) {
            console.log(error);
        }

        setIsPostingOrder(false);
    };

    const startCheckThatOrderIsMined = async (id: number) => {
        let tryCount = 0;
        const interval = setInterval(async () => {
            try {
                const response = await axios.get(`${API_URL}/orders/${id}`);

                if (response.data.payment_address != null) {
                    clearInterval(interval);
                    setInvoice(response.data.payment_address);
                    nextStep();
                    startCheckThatOrderIsPaid(id);
                    return;
                }

                tryCount++;
            } catch (error) {
                console.log(error);
            }

            if (tryCount > 10) {
                clearInterval(interval);
                setTakingTooLong(true);
            }
        }, 2500);
    };

    const startCheckThatOrderIsPaid = async (id: number) => {
        const interval = setInterval(async () => {
            try {
                const response = await axios.get(`${API_URL}/orders/${id}`);
                if (response.data.status_id > 2) {
                    clearInterval(interval);
                    nextStep();
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        }, 2500);
    };

    return (
        <Stepper active={stepperActivePage}>
            <Stepper.Step
                label='Amazon link'
                description='Insert the link of the product'
                icon={<FaAmazon />}
            >
                <form onSubmit={urlForm.onSubmit(getArticleInfoFromUrl)}>
                    <TextInput
                        label="Enter the Amazon link of the product you'd like to order"
                        placeholder='https://amazon.com/...'
                        key={urlForm.key('url')}
                        {...urlForm.getInputProps('url')}
                    />

                    <Button type='submit' mt='sm' loading={isUrlLoading}>
                        Load
                    </Button>
                </form>
            </Stepper.Step>

            <Stepper.Step
                label='Confirm item'
                description='Confirm that the information are correct'
                icon={<FaInfo />}
            >
                {product == null ? (
                    <></>
                ) : (
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Carousel w='100%' h='500' loop>
                                {product.pictures.map((imageUrl, i) => (
                                    <Carousel.Slide key={i}>
                                        <Center>
                                            <Image
                                                src={imageUrl}
                                                w='auto'
                                                fit='contain'
                                                h='500'
                                            />
                                        </Center>
                                    </Carousel.Slide>
                                ))}
                            </Carousel>
                        </Grid.Col>

                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Tooltip
                                label={product.title}
                                multiline
                                withArrow
                                maw={300}
                            >
                                <Title lineClamp={3} role='button'>
                                    {product.title}
                                </Title>
                            </Tooltip>
                            <Text size='xl'>
                                Price:{' '}
                                <Text fw='bold' span>
                                    {(product.price / 100).toFixed(2)}{' '}
                                    {currencies[product.currency] ?? '$'}
                                </Text>
                            </Text>
                            <Text size='xl'>
                                Color:{' '}
                                <Text fw='bold' span>
                                    {product.color ?? '-'}
                                </Text>
                            </Text>
                            <Text size='xl'>
                                Size:{' '}
                                <Text fw='bold' span>
                                    {product.size ?? '-'}
                                </Text>
                            </Text>

                            <Divider my='sm'></Divider>

                            <Text size='xl'>Is this the right product?</Text>
                            <Flex gap='sm'>
                                <Button
                                    onClick={postOrder}
                                    loading={isPostingOrder}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant='light'
                                    onClick={prevStep}
                                    disabled={isPostingOrder}
                                >
                                    No
                                </Button>
                            </Flex>
                        </Grid.Col>
                    </Grid>
                )}
            </Stepper.Step>

            <Stepper.Step
                label='Wait for mining'
                description='Wait that someone pick your order'
                icon={<FaRegClock />}
            >
                <Flex
                    direction='column'
                    align='center'
                    gap='sm'
                    hidden={takingTooLong}
                >
                    <Loader type='bars' />
                    <Title>Wait while a miner pick up your order...</Title>
                </Flex>

                <Flex
                    direction='column'
                    align='center'
                    gap='sm'
                    hidden={!takingTooLong}
                >
                    <FaHourglassHalf fontSize={48} />
                    <Title hidden={!takingTooLong}>
                        It looks like this will take a while, feel free to close
                        this page. You will get notified once a miner picks your
                        order.
                    </Title>
                </Flex>
            </Stepper.Step>

            <Stepper.Step
                label='Pay'
                description='Pay the product you have selected'
                icon={<FaBitcoin />}
            >
                <Flex direction='column' align='center' gap='sm'>
                    <Title>Pay this invoice.</Title>
                    {invoice === '' ? (
                        <></>
                    ) : (
                        <QRCodeSVG
                            value={invoice}
                            size={'25%' as unknown as number}
                        />
                    )}
                </Flex>
            </Stepper.Step>

            <Stepper.Completed>
                <Flex direction='column' align='center' gap='sm'>
                    <FaRegFaceSmileWink color='green' fontSize={48} />
                    <Title>
                        Done! You will be notified when your order is shipped.
                    </Title>
                </Flex>
            </Stepper.Completed>
        </Stepper>
    );
}
