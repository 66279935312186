import { useEffect, useRef, useState } from 'react';
import { API_URL } from '../../config';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';
import Order from '../../model/Order';
import {
    Button,
    Center,
    Code,
    Loader,
    Modal,
    Popover,
    Title,
    Text,
    Stack,
    Flex,
} from '@mantine/core';
import { FaCopy, FaRegFaceSmileWink } from 'react-icons/fa6';

type onModalCloseHandler = (orderPaidIstance: Order | null) => void;
type onModalClose = () => void;

export default function PaymentModal({
    showModal,
    closeModal,
    orderId,
    onModalClose,
}: {
    showModal: boolean;
    closeModal: onModalClose;
    orderId: number | null;
    onModalClose: onModalCloseHandler;
}) {
    const [invoice, setInvoice] = useState('');
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
    const [orderPaidIstance, setOrderPaidIstance] = useState<Order | null>(
        null,
    );
    const intervalRef = useRef<any>(null);

    const handleModalClose = () => {
        onModalClose(orderPaidIstance);
        closeModal();
    };

    const startCheckOrderStatus = async () => {
        try {
            const response = await axios.get(`${API_URL}/orders/${orderId}`);
            if (response.data.status_id > 2) {
                // Show invoice paid message
                // setShowPaymentOk(true);
                setOrderPaidIstance(response.data);

                // Stop the interval which check the payment...
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getOrderInvoice = async () => {
        setIsInvoiceLoading(true);

        try {
            const response = await axios.get(`${API_URL}/orders/${orderId}`);
            setInvoice(response.data.payment_address);

            setIsInvoiceLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCopyAddressClick = () => {
        navigator.clipboard.writeText(invoice);
    };

    useEffect(() => {
        if (orderId == null) return;
        getOrderInvoice();
    }, [orderId]);

    useEffect(() => {
        // When the modal is visible, the client start pinging the server
        // to check if the order has been paid (every X seconds)
        const X = 3;
        if (showModal) {
            intervalRef.current = setInterval(startCheckOrderStatus, X * 1000);
        } else if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        // Cleanup...
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [showModal]);

    return (
        <Modal
            title='Pay order'
            size='md'
            opened={showModal}
            onClose={handleModalClose}
        >
            <Stack hidden={orderPaidIstance != null}>
                <Center>
                    <Title>Pay this invoice</Title>
                </Center>
                <Center hidden={!isInvoiceLoading}>
                    <Loader type='bars' />
                </Center>

                <Stack hidden={isInvoiceLoading}>
                    <QRCodeSVG
                        value={invoice}
                        size={'100%' as unknown as number}
                    />
                    <Flex align='center'>
                        <Code w={'100%'}>{invoice}</Code>
                        <Popover
                            withArrow
                            position='top'
                            onOpen={handleCopyAddressClick}
                        >
                            <Popover.Target>
                                <Button color='gray' variant='subtle'>
                                    <FaCopy />
                                </Button>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Text>Copied!</Text>
                            </Popover.Dropdown>
                        </Popover>
                    </Flex>
                </Stack>
            </Stack>

            <Stack hidden={orderPaidIstance == null}>
                <Center>
                    <FaRegFaceSmileWink fontSize={100} color='green' />
                </Center>
                <Text>Payment received. You can now close the window.</Text>
                <Button onClick={handleModalClose}>Close</Button>
            </Stack>
        </Modal>
    );
}
