import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import Order, { OrderStatus } from '../../model/Order';
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Pagination,
    Select,
    Skeleton,
    Space,
    Table,
    Text,
    Anchor,
    Badge,
    Stack,
    Tooltip,
} from '@mantine/core';
import {
    FaSort,
    FaSortDown,
    FaSortUp,
    FaUpRightFromSquare,
} from 'react-icons/fa6';
import axios from 'axios';
import { API_URL } from '../../config';
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInMonths,
    differenceInSeconds,
    differenceInWeeks,
    differenceInYears,
} from 'date-fns';
import OrderBadge from '../OrderBadge';
import Flag from 'react-world-flags';
import { useDisclosure } from '@mantine/hooks';
import OrderInfoModal from '../modals/OrderInfoModal';
import PickupCodeModal from '../modals/PickupCodeModal';
import ClaimPaymentModal from '../modals/ClaimPaymentModal';

export default function MinerOpenOrdersTable({ filter }: { filter: string }) {
    const [orders, setOrders] = useState<Order[]>([]);
    const [pageSize, setPageSize] = useState(10);
    const [sorting, setSorting] = useState<any>([]);
    const [areOrdersLoading, setAreOrdersLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: pageSize,
    });
    const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

    const [
        showAddInfoModal,
        { open: openAddInfoModal, close: closeAddInfoModal },
    ] = useDisclosure(false);
    const [
        showPickupCodeModal,
        { open: openPickupCodeModal, close: closePickupCodeModal },
    ] = useDisclosure(false);
    const [
        showClaimPaymentModal,
        { open: openClaimPaymentModal, close: closeClaimPaymentModal },
    ] = useDisclosure(false);

    const getOrders = async () => {
        setAreOrdersLoading(true);

        try {
            const response = await axios.get(`${API_URL}/orders/mined`);
            const openOrders = response.data.filter(
                (order: Order) => order.status_id < OrderStatus.COMPLETED,
            );
            setOrders(openOrders);
        } catch (error) {
            console.log(error);
        }

        setAreOrdersLoading(false);
    };

    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        table.setGlobalFilter(filter);
    }, [filter]);

    useEffect(() => {
        setPagination({ ...pagination, pageSize: pageSize });
    }, [pageSize]);

    const handleOpenAddInfoModal = (orderId: number) => {
        setSelectedOrderId(orderId);
        openAddInfoModal();
    };

    const handleOpenPickupCodeModal = (orderId: number) => {
        setSelectedOrderId(orderId);
        openPickupCodeModal();
    };

    const handleOpenClaimPaymentModal = (orderId: number) => {
        setSelectedOrderId(orderId);
        openClaimPaymentModal();
    };

    const updateOrders = (newOrderInstance: Order | null) => {
        let newOrders = orders;
        if (newOrderInstance) {
            newOrders = orders.map((order) => {
                if (order.id === newOrderInstance.id) {
                    order = newOrderInstance;
                }

                return order;
            });
        }

        setOrders(newOrders);
    };

    const onAddInfoModalClose = (newOrderInstance: Order | null) => {
        updateOrders(newOrderInstance);
        closeAddInfoModal();
    };

    const onPickupCodeModalClose = (newOrderInstance: Order | null) => {
        updateOrders(newOrderInstance);
        closePickupCodeModal();
    };

    const onClaimPaymentModalClose = (newOrderInstance: Order | null) => {
        updateOrders(newOrderInstance);
        closeClaimPaymentModal();
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: '#',
                cell: (info: any) => <Text>{info.getValue()}</Text>,
            },
            {
                accessorKey: null,
                header: 'Country',
                cell: (info: any) => (
                    <Tooltip label='IT' position='top-start'>
                        <Flex justify='start'>
                            <Flag
                                code='IT'
                                style={{ borderRadius: '5px', maxWidth: 30 }}
                            />
                        </Flex>
                    </Tooltip>
                ),
            },
            {
                accessorKey: 'title',
                header: 'Name',
                cell: (info: any) => {
                    let text = info.getValue();
                    if (text.length > 20) text = `${text.substring(0, 20)}...`;

                    return (
                        <Anchor
                            href={info.row.original.url}
                            target='_blank'
                            underline='always'
                        >
                            <FaUpRightFromSquare />
                            <Text span ms='xs'>
                                {text}
                            </Text>
                        </Anchor>
                    );
                },
            },
            {
                accessorKey: 'address_locker',
                header: 'Shipping Location',
                cell: (info: any) => <Text>{info.getValue()}</Text>,
            },
            {
                accessorKey: 'price',
                header: 'Price',
                cell: (info: any) => (
                    <Text>{(info.getValue() / 100).toFixed(2)}</Text>
                ),
            },
            {
                accessorKey: 'currency',
                header: 'Currency',
                cell: (info: any) => <Text>{info.getValue()}</Text>,
            },
            {
                accessorKey: 'created_at',
                header: 'Mined',
                cell: (info: any) => {
                    const date = new Date(info.getValue());
                    const now = new Date();

                    let differences = [
                        differenceInMonths(now, date),
                        differenceInWeeks(now, date),
                        differenceInDays(now, date),
                        differenceInHours(now, date),
                        differenceInMinutes(now, date),
                        differenceInSeconds(now, date),
                    ];

                    let timeDifference = `${differenceInYears(now, date)} year ago`;
                    if (differences[0] > 0) {
                        timeDifference = `${differences[0]} month${differences[0] === 1 ? '' : 's'} ago`;
                    } else if (differences[1] > 0) {
                        timeDifference = `${differences[1]} week${differences[1] === 1 ? '' : 's'} ago`;
                    } else if (differences[2] > 0) {
                        timeDifference = `${differences[2]} day${differences[2] === 1 ? '' : 's'} ago`;
                    } else if (differences[3] > 0) {
                        timeDifference = `${differences[3]} hour${differences[3] === 1 ? '' : 's'} ago`;
                    } else if (differences[4] > 0) {
                        timeDifference = `${differences[4]} minute${differences[4] === 1 ? '' : 's'} ago`;
                    } else if (differences[5] > 0) {
                        timeDifference = `${differences[5]} second${differences[5] === 1 ? '' : 's'} ago`;
                    }

                    return <Text>{timeDifference}</Text>;
                },
            },
            {
                accessorKey: 'status_id',
                header: 'Status',
                cell: (info: any) => (
                    <Stack gap='xs'>
                        <OrderBadge orderStatus={info.getValue()}></OrderBadge>
                        {info.getValue() === OrderStatus.PROCESSING ? (
                            <Badge color='black' variant='light'>
                                Payment required
                            </Badge>
                        ) : (
                            ''
                        )}
                        {info.getValue() === OrderStatus.PROCESSED ? (
                            <Badge color='black' variant='light'>
                                Action required
                            </Badge>
                        ) : (
                            ''
                        )}
                        {info.getValue() === OrderStatus.SHIPPING ? (
                            <Badge color='black' variant='light'>
                                Pickup code required
                            </Badge>
                        ) : (
                            ''
                        )}
                        {info.getValue() === OrderStatus.SHIPPED ? (
                            <Badge color='green' variant='light'>
                                Pickup code available
                            </Badge>
                        ) : (
                            ''
                        )}
                    </Stack>
                ),
            },
            {
                id: 'action',
                accessorKey: null,
                header: '',
                enableSorting: false,
                cell: (info: any) => {
                    let actions = <></>;
                    const order = info.row.original;
                    if (
                        order.status_id === OrderStatus.PROCESSED &&
                        order.miner_id === (window as any).loggedUser.id
                    ) {
                        actions = (
                            <Button
                                onClick={() =>
                                    handleOpenAddInfoModal(info.row.original.id)
                                }
                            >
                                Add info
                            </Button>
                        );
                    } else if (
                        order.status_id === OrderStatus.SHIPPING &&
                        order.miner_id === (window as any).loggedUser.id
                    ) {
                        actions = (
                            <Button
                                onClick={() =>
                                    handleOpenPickupCodeModal(
                                        info.row.original.id,
                                    )
                                }
                            >
                                Add code
                            </Button>
                        );
                    } else if (
                        order.status_id === OrderStatus.RECEIVED &&
                        order.miner_id === (window as any).loggedUser.id
                    ) {
                        actions = (
                            <Button
                                onClick={() =>
                                    handleOpenClaimPaymentModal(
                                        info.row.original.id,
                                    )
                                }
                            >
                                Claim payment
                            </Button>
                        );
                    }

                    return actions;
                },
            },
        ],
        [],
    );

    const table = useReactTable({
        data: orders,
        columns: columns,
        state: {
            sorting,
            pagination,
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    return (
        <Box>
            <OrderInfoModal
                showModal={showAddInfoModal}
                closeModal={closeAddInfoModal}
                orderId={selectedOrderId}
                onModalClose={onAddInfoModalClose}
            />
            <PickupCodeModal
                showModal={showPickupCodeModal}
                closeModal={closePickupCodeModal}
                orderId={selectedOrderId}
                onModalClose={onPickupCodeModalClose}
            />
            <ClaimPaymentModal
                showModal={showClaimPaymentModal}
                closeModal={closeClaimPaymentModal}
                orderId={selectedOrderId}
                onModalClose={onClaimPaymentModalClose}
            />

            <Table highlightOnHover>
                <Table.Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Table.Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Table.Th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    role='button'
                                >
                                    <Flex justify='start'>
                                        {!header.column.getCanSort() ? (
                                            ''
                                        ) : (
                                            <>
                                                {!header.column.getIsSorted() ? (
                                                    <Text me='xs' c='dimmed'>
                                                        <FaSort />
                                                    </Text>
                                                ) : header.column.getIsSorted() ===
                                                  'desc' ? (
                                                    <Text me='xs' c='dimmed'>
                                                        <FaSortDown />
                                                    </Text>
                                                ) : (
                                                    <Text me='xs' c='dimmed'>
                                                        <FaSortUp />
                                                    </Text>
                                                )}
                                            </>
                                        )}
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </Flex>
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    ))}
                </Table.Thead>

                <Table.Tbody hidden={areOrdersLoading}>
                    {table.getRowModel().rows.map((row) => (
                        <Table.Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <Table.Td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </Table.Td>
                            ))}
                        </Table.Tr>
                    ))}

                    {table.getRowModel().rows.length === 0 ? (
                        <Table.Tr>
                            <Table.Td colSpan={columns.length + 1}>
                                <Center>
                                    <Text c='dimmed'>No orders found.</Text>
                                </Center>
                            </Table.Td>
                        </Table.Tr>
                    ) : (
                        <></>
                    )}
                </Table.Tbody>

                <Table.Tbody hidden={!areOrdersLoading}>
                    {Array(pageSize)
                        .fill(0)
                        .map((_, i) => (
                            <Table.Tr key={i}>
                                {Array(columns.length)
                                    .fill(0)
                                    .map((_, j) => (
                                        <Table.Td key={j}>
                                            <Skeleton width='70%'>
                                                <Space h='lg' />
                                            </Skeleton>
                                        </Table.Td>
                                    ))}
                            </Table.Tr>
                        ))}
                </Table.Tbody>
            </Table>

            <Divider my='md' />

            <Flex justify='space-between'>
                <Select
                    data={[
                        { value: '5', label: '5' },
                        { value: '10', label: '10' },
                        { value: '15', label: '15' },
                    ]}
                    value={pageSize.toString()}
                    onChange={(value, _) =>
                        setPageSize(value == null ? 10 : parseInt(value))
                    }
                />

                <Pagination
                    value={pagination.pageIndex + 1}
                    total={table.getPageCount()}
                    onChange={(page) =>
                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: page - 1,
                        }))
                    }
                />
            </Flex>
        </Box>
    );
}
