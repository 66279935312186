export default interface Order {
    id: number;
    created_at: string;
    status_id: number;
    creator_id: number;
    miner_id?: number | null;
    tracking?: string | null;
    collection_code?: string | null;
    pub_miner?: string | null;
    pub_buyer: string;
    pub_server: string;
    payment_address?: string | null;
    price: number;
    currency: string;
    title: string;
    desctiption?: string | null;
    url: string;
    total_sats?: number | null;
    address_id: number;
    address_cap: string;
    address_locker: string;
}

export const OrderStatus = {
    CREATED: 1,
    PROCESSING: 2,
    PAYMENT_PENDING: 3,
    PROCESSED: 4,
    SHIPPING: 5,
    SHIPPED: 6,
    RECEIVED: 7,
    DISPUTED: 8,
    COMPLETED: 9,
    CANCELLED: 10,
};
