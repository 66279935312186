import { Badge } from '@mantine/core';

export default function OrderBadge({ orderStatus }: { orderStatus: number }) {
    return orderStatus === 1 ? (
        <Badge variant='light' color='orange'>
            New!
        </Badge>
    ) : orderStatus === 2 ? (
        <Badge variant='light' color='grape'>
            Processing
        </Badge>
    ) : orderStatus === 3 ? (
        <Badge variant='light' color='violet'>
            Confirming
        </Badge>
    ) : orderStatus === 4 ? (
        <Badge variant='light' color='indigo'>
            Processed
        </Badge>
    ) : orderStatus === 5 ? (
        <Badge variant='light' color='blue'>
            Shipping
        </Badge>
    ) : orderStatus === 6 ? (
        <Badge variant='light' color='cyan'>
            Shipped
        </Badge>
    ) : orderStatus === 7 ? (
        <Badge variant='light' color='teal'>
            Received
        </Badge>
    ) : orderStatus === 8 ? (
        <Badge variant='light' color='red'>
            Disputed
        </Badge>
    ) : orderStatus === 9 ? (
        <Badge variant='light' color='green'>
            Completed
        </Badge>
    ) : (
        <Badge variant='light' color='red'>
            Cancelled
        </Badge>
    );
}
