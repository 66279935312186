import { Button, Center, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { checkMnemonic, encryptMnemonic } from '../../utils/user.utils';

type onModalClose = () => void;

export default function ImportMnemonicModal({
    showModal,
    closeModal,
    xpub,
}: {
    showModal: boolean;
    closeModal: onModalClose;
    xpub: string | null;
}) {
    const mnemonicForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            mnemonic: '',
        },

        validate: {
            mnemonic: (value) =>
                checkMnemonic(value, xpub ?? '') ? null : 'Invalid mnemonic',
        },
    });

    const updateMnemonic = (values: typeof mnemonicForm.values) => {
        const { mnemonic } = values;
        localStorage.setItem(
            window.loggedUser.email,
            JSON.stringify(
                encryptMnemonic(mnemonic, window.loggedUser.seed_password),
            ),
        );
        window.loggedUser.mnemonic = mnemonic;

        closeModal();
    };

    return (
        <Modal
            title='Restore mnemonic'
            size='lg'
            opened={showModal}
            onClose={closeModal}
            closeOnClickOutside={false}
            closeOnEscape={false}
            withCloseButton={false}
        >
            <form onSubmit={mnemonicForm.onSubmit(updateMnemonic)}>
                <TextInput
                    label='Mnemonic'
                    placeholder='abandon main hospital ...'
                    key={mnemonicForm.key('mnemonic')}
                    {...mnemonicForm.getInputProps('mnemonic')}
                />

                <Center mt='md'>
                    <Button type='submit'>Save</Button>
                </Center>
            </form>
        </Modal>
    );
}
