import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { API_URL } from '../config';
import { ProgressBar } from 'react-bootstrap';

export default function Profile() {
    const [user, setUser] = useState(null);
    const [inviteCodeData, setInviteCodeData] = useState(null);
    useEffect(() => {
        axios.get(`${API_URL}/users/me`).then((response) => {
            setUser(response.data);
        });
        axios.get(`${API_URL}/users/invite_code`).then((response) => {
            setInviteCodeData(response.data);
        });
    }, []);

    if (!window.loggedUser)
        return (
            <>
                <h1>Profile</h1>
                <div className='content'>devi loggarti</div>
            </>
        );

    if (!user) {
        return (
            <>
                <h1>Profile</h1>
                <div className='content'>caricamento</div>
            </>
        );
    } else {
        let inviteCodeSection = <></>;

        if (inviteCodeData) {
            inviteCodeSection = (
                <div className='content'>
                    <h3>Invite code</h3>
                    <div className='row'>
                        <div className='col-1'>Code</div>
                        <div className='col-5'>
                            <Badge bg='primary'>{inviteCodeData.code}</Badge>
                            <br></br>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1'>Used</div>
                        <div className='col-5'>
                            <ProgressBar
                                now={
                                    (inviteCodeData.used /
                                        inviteCodeData.total) *
                                    100
                                }
                                label={`${inviteCodeData.used}/${inviteCodeData.total}`}
                                style={{ marginTop: '4px' }}
                            ></ProgressBar>
                            <br></br>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className='profile-container'>
                    <h1>
                        Profile{' '}
                        <Button
                            variant='outline-primary'
                            style={{ marginRight: '4px' }}
                            onClick={() => {}}
                        >
                            edit
                        </Button>
                    </h1>
                    <div className='content'>
                        <h3>{user.username}</h3>
                        <ul>
                            <li>Email: {user.email}</li>
                            <li>Public key: {user.master_public}</li>
                            <li>Address: Via Ciccio Pasticcio n69</li>
                        </ul>
                    </div>
                    {inviteCodeSection}
                </div>
            </>
        );
    }
}
