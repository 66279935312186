import { Divider, Tabs, Title, Box } from '@mantine/core';
import { FaExclamation, FaRegCircleCheck } from 'react-icons/fa6';
import MinerOpenOrdersTable from '../../components/tables/MinerOpenOrdersTable';
import MinerPastOrdersTable from '../../components/tables/MinerPastOrdersTable';
import OrdersToMineTable from '../../components/tables/OrdersToMineTable';

export default function Miner() {
    if (!window.loggedUser) {
        return <h1>Please login first</h1>;
    }

    // TODO: add pagination
    // TODO: add sorting/filtering
    return (
        <>
            <Tabs defaultValue='open-order' color='orange'>
                <Tabs.List>
                    <Tabs.Tab
                        value='open-order'
                        leftSection={<FaExclamation />}
                    >
                        Open orders
                    </Tabs.Tab>
                    <Tabs.Tab
                        value='closed-order'
                        leftSection={<FaRegCircleCheck />}
                    >
                        Past orders
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='open-order'>
                    <MinerOpenOrdersTable filter='' />
                </Tabs.Panel>

                <Tabs.Panel value='closed-order'>
                    <MinerPastOrdersTable filter='' />
                </Tabs.Panel>
            </Tabs>

            <Divider my='md' />

            <Box p='md'>
                <Title>Available orders</Title>
                <OrdersToMineTable filter='' />
            </Box>
        </>
    );
}
