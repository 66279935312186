import { Button, Tabs } from '@mantine/core';
import { FaPlus, FaExclamation, FaRegCircleCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import BuyerOpenOrdersTable from '../../components/tables/BuyerOpenOrdersTable';
import BuyerPastOrdersTable from '../../components/tables/BuyerPastOrdersTable';

export default function Buyer() {
    const navigate = useNavigate();

    if (!window.loggedUser) {
        return <h1>Please login first</h1>;
    }

    return (
        <>
            <Button
                leftSection={<FaPlus />}
                mb='md'
                onClick={() => navigate('/buyer/new')}
            >
                New order
            </Button>

            <Tabs defaultValue='open-order' color='orange'>
                <Tabs.List>
                    <Tabs.Tab
                        value='open-order'
                        leftSection={<FaExclamation />}
                    >
                        Open orders
                    </Tabs.Tab>
                    <Tabs.Tab
                        value='closed-order'
                        leftSection={<FaRegCircleCheck />}
                    >
                        Past orders
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='open-order'>
                    <BuyerOpenOrdersTable filter='' />
                </Tabs.Panel>

                <Tabs.Panel value='closed-order'>
                    <BuyerPastOrdersTable filter='' />
                </Tabs.Panel>
            </Tabs>
        </>
    );
}
