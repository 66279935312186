import { NavLink, Text } from '@mantine/core';
import { FaBitcoin, FaChevronRight, FaPersonDigging } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

export default function LeftNavbar() {
    const navigate = useNavigate();

    return (
        <>
            <NavLink
                label={<Text size='lg'>Miner</Text>}
                leftSection={<FaPersonDigging />}
                rightSection={<FaChevronRight />}
                onClick={() => navigate('/miner')}
                color='orange'
                active={window.location.pathname === '/miner'}
            />

            <NavLink
                label={<Text size='lg'>Buyer</Text>}
                leftSection={<FaBitcoin />}
                rightSection={<FaChevronRight />}
                onClick={() => navigate('/buyer')}
                color='orange'
                active={window.location.pathname === '/buyer'}
            />
        </>
    );
}
