import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import Root from './routes/Root';
import Buyer from './routes/Buyer/Home';

import '@mantine/core/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createTheme, MantineProvider } from '@mantine/core';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Root />
//     </BrowserRouter>
//   </React.StrictMode>
// );

const theme = createTheme({
    primaryColor: 'orange',
    defaultRadius: 'md',
});

root.render(
    <MantineProvider theme={theme}>
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    </MantineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
