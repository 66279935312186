import { useEffect, useState } from 'react';
import Order from '../../model/Order';
import axios from 'axios';
import { API_URL } from '../../config';
import { Button, Center, Flex, Modal, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

type onModalCloseHandler = (orderInstance: Order | null) => void;
type onModalClose = () => void;

export default function OrderInfoModal({
    showModal,
    closeModal,
    orderId,
    onModalClose,
}: {
    showModal: boolean;
    closeModal: onModalClose;
    orderId: number | null;
    onModalClose: onModalCloseHandler;
}) {
    const giftLinkForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            giftLink: '',
        },

        validate: {
            giftLink: (value) =>
                /^https:\/\/(www\.)?amazon\.(com|[a-z]{2,3})(\/.*)?$/.test(
                    value,
                )
                    ? null
                    : 'Invalid url',
        },
    });
    const [orderInstance, setOrderInstance] = useState<Order | null>(null);

    useEffect(() => {
        if (orderInstance == null) return;
        handleModalClose();
    }, [orderInstance]);

    const handleModalClose = () => {
        onModalClose(orderInstance);
        closeModal();
    };

    const handleInfoSave = async (values: typeof giftLinkForm.values) => {
        try {
            await axios.post(`${API_URL}/orders/${orderId}/tracking`, {
                data: values.giftLink,
            });
            const order: Order = (
                await axios.get(`${API_URL}/orders/${orderId}`)
            ).data;
            setOrderInstance(order);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            title='Add gift link'
            size='md'
            opened={showModal}
            onClose={handleModalClose}
        >
            <Center>
                <Title>Add order gift link</Title>
            </Center>

            <form onSubmit={giftLinkForm.onSubmit(handleInfoSave)}>
                <TextInput
                    label='Link'
                    placeholder='https://gift.amazon.com/...'
                    key={giftLinkForm.key('giftLink')}
                    {...giftLinkForm.getInputProps('giftLink')}
                />

                <Flex justify='end'>
                    <Button type='submit' mt='md'>
                        Save
                    </Button>
                </Flex>
            </form>
        </Modal>
    );
}
