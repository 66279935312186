import {
    Button,
    Center,
    Code,
    Divider,
    Flex,
    Modal,
    TextInput,
    Title,
    Text,
    Grid,
    NavLink,
    Box,
    ScrollArea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import L from 'leaflet';
import { Mnemonic, Network, Signer } from 'lwk_wasm';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
import { API_URL } from '../../config';
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import Locker from '../../model/Locker';
import { encryptMnemonic } from '../../utils/user.utils';

const blueIcon = new L.Icon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
const redIcon = new L.Icon({
    iconRetinaUrl: require('../../resources/marker-icon-2x-red.png'),
    iconUrl: require('../../resources/marker-icon-red.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

L.Icon.Default.mergeOptions(blueIcon);

type onLoginModalClose = () => void;
type Town = { name: string; postal_code: string };

export default function SetupModal({
    showModal,
    closeModal,
}: {
    showModal: boolean;
    closeModal: onLoginModalClose;
}) {
    const searchCapForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            cap: '',
        },
    });
    const [mnemonic, _] = useState(Mnemonic.fromRandom(12).toString());
    const [searchedCode, setSearchedCode] = useState<string | null>(null);
    const [towns, setTowns] = useState<Town[]>([]);
    const [activeTown, setActiveTown] = useState<Town | null>(null);
    const [lockers, setLockers] = useState<Locker[]>([]);
    const [activeLocker, setActiveLocker] = useState<Locker | null>(null);
    const [lockersHidden, setLockersHidden] = useState(true);

    const scrollAreaViewport = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (scrollAreaViewport.current == null) return;

        scrollAreaViewport.current!.scrollTo({ top: 0 });
    }, [lockersHidden]);

    const searchTowns = async (values: typeof searchCapForm.values) => {
        const { cap: zipCode } = values;

        try {
            const response = await axios.get(
                `${API_URL}/locker?zip_code=${zipCode}`,
            );
            setTowns(response.data);
            setSearchedCode(zipCode);
            setLockersHidden(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleTownClick = async (town: Town) => {
        try {
            setActiveTown(town);
            const url = `${API_URL}/locker/search?zip_code=${town.postal_code}&city_name=${town.name}`;
            const response = await axios.get(url);
            setLockers(response.data);
            setLockersHidden(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSaveSetup = async () => {
        window.loggedUser.mnemonic = mnemonic;
        localStorage.setItem(
            window.loggedUser.email,
            JSON.stringify(
                encryptMnemonic(mnemonic, window.loggedUser.seed_password),
            ),
        );

        const signer = new Signer(new Mnemonic(mnemonic), Network.mainnet());
        let locker = `${activeLocker!.name} - ${activeLocker!.city}`;

        try {
            await axios.put(`${API_URL}/users/address`, {
                cap: activeLocker!.postal_code,
                locker,
            });
            await axios.put(`${API_URL}/users`, {
                master_public: signer.getMasterXpub().toString(),
            });
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const MapCenter = ({ lat, lon }: { lat: number; lon: number }) => {
        const map = useMap();
        map.setView([lat, lon]);

        useEffect(() => {
            if (lockers.length > 0) {
                const bounds = new L.LatLngBounds(
                    lockers.map((locker: any) => [
                        locker.latitude,
                        locker.longitude,
                    ]),
                );
                map.fitBounds(bounds);
            }
        }, [lockers]);

        return null;
    };

    return (
        <Modal
            title='Initial setup'
            size='xl'
            opened={showModal}
            onClose={closeModal}
            closeOnClickOutside={false}
            closeOnEscape={false}
            withCloseButton={false}
        >
            <Flex direction='column' align='center' gap='md'>
                <Title order={3}>Select a locker</Title>
                <form onSubmit={searchCapForm.onSubmit(searchTowns)}>
                    <Flex align='end' gap='xs'>
                        <TextInput
                            label='Postal code'
                            key={searchCapForm.key('cap')}
                            {...searchCapForm.getInputProps('cap')}
                        />

                        <Button type='submit'>Search</Button>
                    </Flex>
                </form>

                <Grid w={'100%'}>
                    <Grid.Col span={6}>
                        <ScrollArea h={300} viewportRef={scrollAreaViewport}>
                            <Box hidden={!lockersHidden}>
                                <Center>
                                    <Text
                                        hidden={searchedCode == null}
                                        c='dimmed'
                                    >
                                        Found{' '}
                                        <Text span fw={700}>
                                            {towns.length}
                                        </Text>{' '}
                                        cities for postal code{' '}
                                        <Text span fw={700}>
                                            {searchedCode}
                                        </Text>
                                    </Text>

                                    <Text
                                        hidden={searchedCode != null}
                                        c='dimmed'
                                    >
                                        Search for a city with the form above!
                                    </Text>
                                </Center>
                                {towns.map((town, i) => (
                                    <NavLink
                                        key={i}
                                        label={town.name}
                                        leftSection={<Text>{i + 1}</Text>}
                                        rightSection={<FaChevronRight />}
                                        onClick={() => handleTownClick(town)}
                                    />
                                ))}
                            </Box>

                            <Box hidden={lockersHidden}>
                                <Flex align='center'>
                                    <Button
                                        variant='light'
                                        leftSection={<FaChevronLeft />}
                                        onClick={() => setLockersHidden(true)}
                                    >
                                        Back
                                    </Button>

                                    <Text ms='sm' c='dimmed' fw={700}>
                                        {activeTown?.name}
                                    </Text>
                                </Flex>

                                {lockers.map((locker, i) => (
                                    <NavLink
                                        key={i}
                                        label={
                                            <Text size='md'>{locker.name}</Text>
                                        }
                                        description={
                                            <>
                                                <Text size='sm'>
                                                    {locker.address_line_1}
                                                </Text>
                                                <Text size='sm'>
                                                    {locker.address_line_2 !=
                                                    null
                                                        ? locker.address_line_2
                                                        : ''}
                                                </Text>
                                            </>
                                        }
                                        leftSection={<Text>{i + 1}</Text>}
                                        active={locker.id === activeLocker?.id}
                                        onClick={() => setActiveLocker(locker)}
                                    />
                                ))}
                            </Box>
                        </ScrollArea>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <MapContainer
                            center={[0, 0]}
                            zoom={13}
                            style={{ height: '300px', width: '100%' }}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            />

                            <MapCenter lat={0} lon={0} />
                            {lockers.map((locker) => (
                                <Marker
                                    key={locker.id}
                                    position={[
                                        Number(locker.latitude),
                                        Number(locker.longitude),
                                    ]}
                                    icon={
                                        activeLocker?.id === locker.id
                                            ? redIcon
                                            : blueIcon
                                    }
                                    zIndexOffset={
                                        activeLocker?.id === locker.id ? 1 : 0
                                    }
                                />
                            ))}
                        </MapContainer>
                    </Grid.Col>
                </Grid>

                <Center hidden={activeLocker == null}>
                    <Text c='dimmed'>
                        Selected locker:{' '}
                        <Text span fw={700}>
                            {activeLocker?.name ?? ''} (
                            {activeLocker?.city ?? ''})
                        </Text>
                    </Text>
                </Center>
            </Flex>

            <Divider my='md' />

            <Flex direction='column' align='center' gap='md'>
                <Title order={3}>Backup this mnemonic!</Title>
                <Code>{mnemonic}</Code>
                <Button
                    disabled={activeLocker == null}
                    onClick={handleSaveSetup}
                >
                    I HAVE BACKED UP MY MNEMONIC
                </Button>
            </Flex>
        </Modal>
    );
}
