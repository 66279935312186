import {
    Container,
    Group,
    Modal,
    Button,
    TextInput,
    Text,
    Center,
    PinInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import { ChevronLeft } from 'react-bootstrap-icons';
import { API_URL } from '../../config';

type onLoginModalClose = () => void;
type checkCurrentUserCallback = () => void;

export default function LoginModal({
    showModal,
    closeModal,
    setUser,
    checkCurrentUser,
}: {
    showModal: boolean;
    closeModal: onLoginModalClose;
    setUser: Dispatch<SetStateAction<string | null>>;
    checkCurrentUser: checkCurrentUserCallback;
}) {
    const [showLoginCode, setShowLoginCode] = useState(false);
    const [showInviteCode, setShowInviteCode] = useState(false);

    const loginForm = useForm({
        mode: 'controlled',
        initialValues: {
            email: '',
        },

        validate: {
            email: (value) =>
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
                    ? null
                    : 'Invalid email',
        },
    });

    const loginCodeForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            code: '',
        },

        validate: {
            code: (value) => (value.length === 6 ? null : 'Invalid code.'),
        },
    });

    const inviteCodeForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            code: '',
        },

        validate: {
            code: (value) => (value.length === 8 ? null : 'Invalid code.'),
        },
    });

    const requestLoginCode = async (values: typeof loginForm.values) => {
        try {
            const repsonse = await axios.post(`${API_URL}/auth/login`, {
                email: values.email,
            });
            // REMOVE THIS IN PROD!!!
            console.log(repsonse);

            loginCodeForm.reset();
            setShowLoginCode(true);
        } catch (error) {
            if (
                axios.isAxiosError(error) &&
                error.response?.data.invite_code_required
            ) {
                setShowInviteCode(true);
                return;
            }

            console.log(error);
        }
    };

    const sendLoginCode = async (values: typeof loginCodeForm.values) => {
        const { code } = values;

        try {
            await axios.post(`${API_URL}/auth/login/${code}`);
            setUser(loginForm.values.email);
            checkCurrentUser();
            closeModal();
        } catch (error) {
            if (
                axios.isAxiosError(error) &&
                (error.response?.status ?? 500) < 500
            ) {
                loginCodeForm.setErrors({ code: true });
            }

            console.log(error);
        }
    };

    const sendInviteCode = async (values: typeof inviteCodeForm.values) => {
        const { code } = values;

        try {
            const localEmail = loginForm.values.email;

            await axios.post(`${API_URL}/auth/login/invite_code`, {
                email: localEmail,
                invite_code: code,
            });
            setShowLoginCode(true);
            setShowInviteCode(false);
        } catch (error) {
            if (
                axios.isAxiosError(error) &&
                (error.response?.status ?? 500) < 500
            ) {
                inviteCodeForm.setErrors({ code: true });
            }

            console.log(error);
        }
    };

    return (
        <Modal title='Login' size='lg' opened={showModal} onClose={closeModal}>
            <Container hidden={showLoginCode || showInviteCode}>
                <form onSubmit={loginForm.onSubmit(requestLoginCode)}>
                    <TextInput
                        label='Email'
                        placeholder='email@example.com'
                        key={loginForm.key('email')}
                        {...loginForm.getInputProps('email')}
                    />

                    <Group justify='flex-end' mt='md'>
                        <Button
                            type='submit'
                            color='black'
                            radius='xs'
                            w={'100%'}
                        >
                            Login
                        </Button>
                        <Button
                            color='black'
                            radius='xs'
                            w={'100%'}
                            variant='outline'
                            disabled
                        >
                            Login with NOSTR
                        </Button>
                    </Group>
                </form>
            </Container>

            <Container hidden={!showLoginCode}>
                <form onSubmit={loginCodeForm.onSubmit(sendLoginCode)}>
                    <div
                        className='w-100 text-left mb-2'
                        role='button'
                        onClick={() => setShowLoginCode(false)}
                    >
                        <span className='text-secondary d-flex align-items-center'>
                            <ChevronLeft className='me-1' />
                            <span>Back</span>
                        </span>
                    </div>

                    <Center>
                        <Text>
                            A login code was sent to{' '}
                            <strong>{loginForm.values.email}</strong>. Insert it
                            to login.
                        </Text>
                    </Center>

                    <Center mt='md'>
                        <PinInput
                            placeholder='X'
                            length={6}
                            key={loginCodeForm.key('code')}
                            {...loginCodeForm.getInputProps('code')}
                        />
                    </Center>

                    <Center
                        mt='xs'
                        hidden={Object.keys(loginCodeForm.errors).length === 0}
                    >
                        <Text c='red'>Invalid code</Text>
                    </Center>

                    <Button
                        type='submit'
                        color='black'
                        radius='xs'
                        w={'100%'}
                        mt='md'
                    >
                        Login
                    </Button>
                </form>
            </Container>

            <Container hidden={!showInviteCode}>
                <form onSubmit={inviteCodeForm.onSubmit(sendInviteCode)}>
                    <div
                        className='w-100 text-left mb-2'
                        role='button'
                        onClick={() => setShowInviteCode(false)}
                    >
                        <span className='text-secondary d-flex align-items-center'>
                            <ChevronLeft className='me-1' />
                            <span>Back</span>
                        </span>
                    </div>

                    <Center my='sm'>
                        <Text>
                            An invite code is required to sign-up to the closed
                            beta. Insert it here if you have one.
                        </Text>
                    </Center>

                    <Center mt='md'>
                        <PinInput
                            placeholder='X'
                            length={8}
                            key={inviteCodeForm.key('code')}
                            {...inviteCodeForm.getInputProps('code')}
                        />
                    </Center>

                    <Center
                        mt='xs'
                        hidden={Object.keys(inviteCodeForm.errors).length === 0}
                    >
                        <Text c='red'>Invalid code</Text>
                    </Center>

                    <Button
                        type='submit'
                        color='black'
                        radius='xs'
                        w={'100%'}
                        mt='md'
                    >
                        Next
                    </Button>
                </form>
            </Container>
        </Modal>
    );
}
