import { useEffect, useMemo, useState } from 'react';
import Order, { OrderStatus } from '../../model/Order';
import { API_URL } from '../../config';
import axios from 'axios';
import {
    Anchor,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Pagination,
    Select,
    Skeleton,
    Space,
    Table,
    Text,
    Tooltip,
} from '@mantine/core';
import {
    FaSort,
    FaSortDown,
    FaSortUp,
    FaUpRightFromSquare,
} from 'react-icons/fa6';
import OrderBadge from '../OrderBadge';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import Flag from 'react-world-flags';

export default function MinerPastOrdersTable({ filter }: { filter: string }) {
    const [orders, setOrders] = useState<Order[]>([]);
    const [pageSize, setPageSize] = useState(10);
    const [sorting, setSorting] = useState<any>([]);
    const [areOrdersLoading, setAreOrdersLoading] = useState(true);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: pageSize,
    });

    const getOrders = async () => {
        setAreOrdersLoading(true);

        try {
            const response = await axios.get(`${API_URL}/orders/created`);
            const pastOrder = response.data.filter(
                (order: Order) => order.status_id >= OrderStatus.COMPLETED,
            );
            setOrders(pastOrder);
        } catch (error) {
            console.log(error);
        }

        setAreOrdersLoading(false);
    };

    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        table.setGlobalFilter(filter);
    }, [filter]);

    useEffect(() => {
        setPagination({ ...pagination, pageSize: pageSize });
    }, [pageSize]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: '#',
                cell: (info: any) => <Text>{info.getValue()}</Text>,
            },
            {
                accessorKey: null,
                header: 'Country',
                cell: (info: any) => (
                    <Tooltip label='IT' position='top-start'>
                        <Flex justify='start'>
                            <Flag
                                code='IT'
                                style={{ borderRadius: '5px', maxWidth: 30 }}
                            />
                        </Flex>
                    </Tooltip>
                ),
            },
            {
                accessorKey: 'title',
                header: 'Name',
                cell: (info: any) => {
                    let text = info.getValue();
                    if (text.length > 20) text = `${text.substring(0, 20)}...`;

                    return (
                        <Anchor
                            href={info.row.original.url}
                            target='_blank'
                            underline='always'
                        >
                            <FaUpRightFromSquare />
                            <Text span ms='xs'>
                                {text}
                            </Text>
                        </Anchor>
                    );
                },
            },
            {
                accessorKey: 'address_locker',
                header: 'Shipping Location',
                cell: (info: any) => <Text>{info.getValue()}</Text>,
            },
            {
                accessorKey: 'price',
                header: 'Price',
                cell: (info: any) => (
                    <Text>{(info.getValue() / 100).toFixed(2)}</Text>
                ),
            },
            {
                accessorKey: 'currency',
                header: 'Currency',
                cell: (info: any) => <Text>{info.getValue()}</Text>,
            },
            {
                accessorKey: 'status_id',
                header: 'Status',
                cell: (info: any) => (
                    <OrderBadge orderStatus={info.getValue()}></OrderBadge>
                ),
            },
            {
                id: 'action',
                accessorKey: null,
                header: '',
                enableSorting: false,
                cell: (info: any) => <Button>Show info</Button>,
            },
        ],
        [],
    );

    const table = useReactTable({
        data: orders,
        columns: columns,
        state: {
            sorting,
            pagination,
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    return (
        <Box>
            <Table highlightOnHover>
                <Table.Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Table.Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Table.Th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    role='button'
                                >
                                    <Flex justify='start'>
                                        {!header.column.getCanSort() ? (
                                            ''
                                        ) : (
                                            <>
                                                {!header.column.getIsSorted() ? (
                                                    <Text me='xs' c='dimmed'>
                                                        <FaSort />
                                                    </Text>
                                                ) : header.column.getIsSorted() ===
                                                  'desc' ? (
                                                    <Text me='xs' c='dimmed'>
                                                        <FaSortDown />
                                                    </Text>
                                                ) : (
                                                    <Text me='xs' c='dimmed'>
                                                        <FaSortUp />
                                                    </Text>
                                                )}
                                            </>
                                        )}
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </Flex>
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    ))}
                </Table.Thead>

                <Table.Tbody hidden={areOrdersLoading}>
                    {table.getRowModel().rows.map((row) => (
                        <Table.Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <Table.Td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </Table.Td>
                            ))}
                        </Table.Tr>
                    ))}

                    {table.getRowModel().rows.length === 0 ? (
                        <Table.Tr>
                            <Table.Td colSpan={columns.length + 1}>
                                <Center>
                                    <Text c='dimmed'>No orders found.</Text>
                                </Center>
                            </Table.Td>
                        </Table.Tr>
                    ) : (
                        <></>
                    )}
                </Table.Tbody>

                <Table.Tbody hidden={!areOrdersLoading}>
                    {Array(pageSize)
                        .fill(0)
                        .map((_, i) => (
                            <Table.Tr key={i}>
                                {Array(columns.length)
                                    .fill(0)
                                    .map((_, j) => (
                                        <Table.Td key={j}>
                                            <Skeleton width='70%'>
                                                <Space h='lg' />
                                            </Skeleton>
                                        </Table.Td>
                                    ))}
                            </Table.Tr>
                        ))}
                </Table.Tbody>
            </Table>

            <Divider my='md' />

            <Flex justify='space-between'>
                <Select
                    data={[
                        { value: '5', label: '5' },
                        { value: '10', label: '10' },
                        { value: '15', label: '15' },
                    ]}
                    value={pageSize.toString()}
                    onChange={(value, _) =>
                        setPageSize(value == null ? 10 : parseInt(value))
                    }
                />

                <Pagination
                    value={pagination.pageIndex + 1}
                    total={table.getPageCount()}
                    onChange={(page) =>
                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: page - 1,
                        }))
                    }
                />
            </Flex>
        </Box>
    );
}
