import { useEffect, useState } from 'react';
import Order from '../../model/Order';
import axios from 'axios';
import { API_URL } from '../../config';
import {
    Anchor,
    Button,
    Center,
    Flex,
    Modal,
    Title,
    Text,
    PinInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';

type onModalCloseHandler = (orderInstance: Order | null) => void;
type onModalClose = () => void;

export default function OrderInfoModal({
    showModal,
    closeModal,
    orderId,
    onModalClose,
}: {
    showModal: boolean;
    closeModal: onModalClose;
    orderId: number | null;
    onModalClose: onModalCloseHandler;
}) {
    const orderCodeForm = useForm({
        mode: 'uncontrolled',
        initialValues: {
            pickupCode: '',
        },

        validate: {
            pickupCode: (value) =>
                /^[0-9]{6}$/.test(value) ? null : 'Invalid code',
        },
    });
    const [orderInstance, setOrderInstance] = useState<Order | null>(null);
    const [pickupCode, setPickupCode] = useState<string | null>(null);

    useEffect(() => {
        if (orderInstance == null) return;
        handleModalClose();
    }, [orderInstance]);

    const handleModalClose = () => {
        onModalClose(orderInstance);
        closeModal();
    };

    const handleInfoSave = async (values: typeof orderCodeForm.values) => {
        setPickupCode(values.pickupCode);
    };

    const saveCode = async () => {
        try {
            await axios.post(`${API_URL}/orders/${orderId}/collection_code`, {
                data: pickupCode,
            });
            const order: Order = (
                await axios.get(`${API_URL}/orders/${orderId}`)
            ).data;
            setOrderInstance(order);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            title='Add pickup code'
            size='md'
            opened={showModal}
            onClose={handleModalClose}
        >
            <Center>
                <Title>Add pickup code</Title>
            </Center>

            <form onSubmit={orderCodeForm.onSubmit(handleInfoSave)}>
                <Center>
                    <PinInput
                        placeholder='x'
                        length={6}
                        key={orderCodeForm.key('pickupCode')}
                        {...orderCodeForm.getInputProps('pickupCode')}
                    />
                </Center>

                {pickupCode == null ? (
                    <Flex justify='end'>
                        <Button type='submit' mt='md'>
                            Save
                        </Button>
                    </Flex>
                ) : (
                    <>
                        <Center mt='md'>
                            <Text>
                                The code you've provided is{' '}
                                <Text span fw='bold'>
                                    {pickupCode}
                                </Text>
                                . Be aware that sending an invalid code will
                                lead to some penalties. Read{' '}
                                <Anchor>our rules</Anchor>. Continue?
                            </Text>
                        </Center>
                        <Flex justify='end'>
                            <Button
                                onClick={closeModal}
                                me='md'
                                variant='subtle'
                                color='black'
                            >
                                NO
                            </Button>
                            <Button onClick={saveCode}>YES</Button>
                        </Flex>
                    </>
                )}
            </form>
        </Modal>
    );
}
