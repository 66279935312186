import { useState, useEffect } from 'react';

import { Routes, Route, useNavigate } from 'react-router-dom';

import axios from 'axios';

import Buyer from './Buyer/Home';
import Home from './Home';
import Profile from './Profile';
import NewOrder from './Buyer/New';
import Miner from './Miner/Home';

import { API_URL } from '../config';
import TakeOrder from './Miner/Buy';

import 'leaflet/dist/leaflet.css';
import {
    AppShell,
    Container,
    Image,
    Button,
    Flex,
    Burger,
    Text,
} from '@mantine/core';
import LoginModal from '../components/modals/LoginModal';
import { useDisclosure } from '@mantine/hooks';
import { checkMnemonic, decryptMnemonic } from '../utils/user.utils';
import SetupModal from '../components/modals/SetupModal';
import ImportMnemonicModal from '../components/modals/ImportMnemonicModal';
import LoggedUserMenu from '../components/navigation/LoggedUserMenu';
import LeftNavbar from '../components/navigation/LeftNavbar';

axios.defaults.withCredentials = true;

export default function Root() {
    const [user, setUser] = useState<string | null>(null);

    const [showLoginModal, { open: openLoginModal, close: closeLoginModal }] =
        useDisclosure(false);
    const [showSetupModal, { open: openSetupModal, close: closeSetupModal }] =
        useDisclosure(false);
    const [
        showImportMnemonicModal,
        { open: openImportMnemonicModal, close: closeImportMnemonicModal },
    ] = useDisclosure(false);
    const [showLeftNavbar, { toggle: toggleLeftNavbar }] = useDisclosure();

    const navigate = useNavigate();

    const checkCurrentUser = async () => {
        try {
            const { data } = await axios.get(`${API_URL}/users/me`);
            setUser(data.email);
            window.loggedUser = data;

            if (data.master_public == null) {
                openSetupModal();
                return;
            }

            if (localStorage.getItem(data.email) == null) {
                openImportMnemonicModal();
                return;
            }

            const localData = JSON.parse(
                localStorage.getItem(data.email) ?? '',
            );
            const mnemonic = decryptMnemonic(
                localData.ct,
                data.seed_password,
                localData.iv,
            );
            if (!checkMnemonic(mnemonic, data.master_public)) {
                openImportMnemonicModal();
                localStorage.removeItem(data.email);
                return;
            }

            window.loggedUser.mnemonic = mnemonic;
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        checkCurrentUser();
    }, []);

    return (
        <>
            <LoginModal
                showModal={showLoginModal}
                closeModal={closeLoginModal}
                setUser={setUser}
                checkCurrentUser={checkCurrentUser}
            />
            <SetupModal
                showModal={showSetupModal}
                closeModal={closeSetupModal}
            />
            <ImportMnemonicModal
                showModal={showImportMnemonicModal}
                closeModal={closeImportMnemonicModal}
                xpub={window.loggedUser?.master_public}
            />

            <AppShell
                header={{ height: 60 }}
                navbar={{
                    width: 300,
                    breakpoint: 'sm',
                    collapsed: { mobile: !showLeftNavbar },
                }}
                padding='md'
            >
                <AppShell.Header bg='black'>
                    <Flex
                        justify='space-between'
                        align='center'
                        px='xs'
                        h={'100%'}
                        w={'100%'}
                    >
                        <Flex h={'100%'} align='center'>
                            <Burger
                                opened={showLeftNavbar}
                                onClick={toggleLeftNavbar}
                                hiddenFrom='sm'
                                size='md'
                                color='orange'
                            />
                            <Image
                                src='/bitpost_logo.jpeg'
                                h={'100%'}
                                w='auto'
                                fit='contain'
                                role='button'
                                onClick={() => navigate('/')}
                            />
                        </Flex>
                        {user ? (
                            <LoggedUserMenu userHandle={user} />
                        ) : (
                            <Button
                                color='orange'
                                size='md'
                                radius='xs'
                                variant='outline'
                                onClick={openLoginModal}
                            >
                                Login
                            </Button>
                        )}
                    </Flex>
                </AppShell.Header>

                <AppShell.Navbar p='md'>
                    <LeftNavbar />
                </AppShell.Navbar>

                <AppShell.Main>
                    <Container fluid>
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/buyer' element={<Buyer />} />
                            <Route path='/buyer/new' element={<NewOrder />} />
                            <Route path='/profile' element={<Profile />} />
                            <Route path='/miner' element={<Miner />} />
                            <Route
                                path='/miner/mine/:id'
                                element={<TakeOrder />}
                            />
                            <Route path='*' element={<Text>Not found</Text>} />
                        </Routes>
                    </Container>
                </AppShell.Main>
            </AppShell>
        </>
    );
}
