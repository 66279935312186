import axios from 'axios';

import { API_URL } from '../config';
import { Pset, Signer, Mnemonic, Network } from 'lwk_wasm';

export default function Home() {
    function addOrder() {
        axios
            .post(
                `${API_URL}/orders`,
                {
                    pub_server: '',
                    price: 200,
                    currency: 'EUR',
                    title: 'Oggetto Prova',
                    url: 'https://amazon.it/....',
                    address_id: 1,
                },
                { headers: { Authorization: window.loggedUser.token } },
            )
            .then((resp) => {
                console.log(resp);
            });
    }
    function mineOrder() {
        axios
            .post(
                `${API_URL}/orders/22/mine/`,
                {},
                { headers: { Authorization: window.loggedUser.token } },
            )
            .then((resp) => {
                console.log(resp);
            })
            .catch((e) => console.log(e));
    }
    function sendInvoice() {
        axios
            .post(
                `${API_URL}/orders/6/invoice/`,
                {
                    invoice:
                        'lnbc35u1pnwwzzapp5d4wpwdlggknsavq84wjd4e5fgkf0e9zjvdqrxz76nf4y027zm8uscqpjsp52j4c9egr5taj7ayvrwkwmqwd5c83txu2xv580nemxk60ce5aeeas9q7sqqqqqqqqqqqqqqqqqqqsqqqqqysgqdqqmqz9gxqyjw5qrzjqwryaup9lh50kkranzgcdnn2fgvx390wgj5jd07rwr3vxeje0glcllllhlgpg0gy5qqqqqlgqqqqqeqqjqypx4d8yn9y639nl288n9u3ar9upwu725859qj9r6hql26nw34pa5y6vgzk37sz3a2tr3wlpmvupuuc4lemvd3m70nvdzgl6ra9yz7vqpwq2692',
                },
                { headers: { Authorization: window.loggedUser.token } },
            )
            .then((resp) => {
                const orig_pset = new Pset(resp.data.psbt);
                const signer = new Signer(
                    new Mnemonic(window.loggedUser.mnemonic),
                    Network.mainnet(),
                );
                const signed_pset = signer.sign(orig_pset).toString();
                console.log(resp.data.psbt);
                console.log(signed_pset);
                setTimeout(() => {
                    sendSignature(signed_pset);
                }, 1500);
            })
            .catch((e) => console.log(e));
    }
    function sendSignature(value) {
        console.log(value);
        axios
            .post(
                `${API_URL}/orders/6/signature/`,
                { signed_psbt: value },
                { headers: { Authorization: window.loggedUser.token } },
            )
            .then((resp) => {
                console.log(resp);
            })
            .catch((e) => console.log(e));
    }
    return (
        <>
            <h1>Home</h1>
            <button onClick={addOrder}>Add order</button>
            <button onClick={mineOrder}>Mine order</button>
            <button onClick={sendInvoice}>Send Invoice</button>
        </>
    );
}
